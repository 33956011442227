



































































































































import { Component, Vue } from "vue-property-decorator";
import {
  IUserProfile,
  IUserProfileUpdate,
  IUserProfileCreate,
} from "@/interfaces";
import {
  dispatchCheckAddress,
  dispatchGetServiceAddress,
} from "@/store/main/actions";
import VuetifyGoogleAutocomplete from "../../VuetifyGoogleAutocomplete.vue";

import {
  dispatchGetUsers,
  dispatchCreateUser,
  dispatchPostCreateBuildingAdmin,
} from "@/store/admin/actions";
import Loading from "../../Loading.vue";

@Component({
  components: { VuetifyGoogleAutocomplete, Loading },
})
export default class CreateUser extends Vue {
  public valid = false;
  public firstName: string = "";
  public lastName: string = "";
  public email: string = "";
  public phone: string = "";
  public isActive: boolean = true;
  public isSuperuser: boolean = true;
  public setPassword = false;
  public password1: string = "";
  public password2: string = "";

  public unit = "";
  public address = "";
  public place = "";
  public placeId = "";
  public addressInfo = "";
  public addressData = "";
  public showLoading = false;
  public tip = "";

  $refs!: Vue["$refs"] & {
    map: typeof VuetifyGoogleAutocomplete;
  };

  public reset() {
    this.password1 = "";
    this.password2 = "";
    this.firstName = "";
    this.lastName = "";
    this.email = "";
    this.phone = "";
    this.isActive = true;
    this.isSuperuser = false;
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public validate() {
    if (this.address) {
      const addresses = this.address.split(", ");
      if (addresses.length < 3) {
        this.tip = "Please select your address from the address selection menu";
      } else {
        this.tip = "";
      }
    }
    return (
      !!this.email && !!this.phone && !this.tip && !!this.address && !!this.unit
    );
  }

  public getAddressData(addressData, placeResultData, id) {
    this.addressData = addressData;
    this.place = placeResultData;
    this.placeId = id;
    this.addressInfo = `Address Detail: ${JSON.stringify(
      addressData
    )} ${JSON.stringify(placeResultData)} ${id}`;
    console.log(addressData, placeResultData, id);
  }

  public updateAddress(newAddress) {
    this.address = newAddress;
  }

  public async submit() {
    this.showLoading = true;
    this.lastName = `BUILDING ADMIN`;
    dispatchPostCreateBuildingAdmin(this.$store, {
      firstName: this.firstName,
      lastName: this.lastName,
      phone: this.phone,
      email: this.email,
      unit: this.unit,
      address: this.address,
      addressData: `${JSON.stringify(this.addressData)}`,
      placeData: `${JSON.stringify(this.place)}`,
      placeId: `${JSON.stringify(this.placeId)}`,
    })
      .then((response) => {
        console.log("create admin user", response);
        this.$router.push("/main/admin/users");
      })
      .catch((error) => {
        this.showLoading = false;
        this.tip = "Please select your address from the address selection menu";
      });
  }
}
