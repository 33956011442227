var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"ma-3 pa-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Create Building Admin User")])]),_c('v-card-text',[[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"label":"Full Name","required":""},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})],1)],1),_c('v-textarea',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"label":"Contact Number","data-vv-name":"phone","required":"","autocomplete":"off","rows":"1","no-resize":""},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('required|email'),expression:"'required|email'"}],attrs:{"label":"E-mail","type":"email","data-vv-name":"email","error-messages":_vm.errors.collect('email'),"required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-row',[_c('v-col',{attrs:{"sm":"12"}},[(!!this.addressData)?_c('div',{staticClass:"MapBorder"},[_c('GmapMap',{staticStyle:{"width":"100%","height":"300px"},attrs:{"center":{
                    lat: _vm.addressData.latitude,
                    lng: _vm.addressData.longitude,
                  },"zoom":18,"map-type-id":"terrain"}},_vm._l(([
                      {
                        position: {
                          lat: _vm.addressData.latitude,
                          lng: _vm.addressData.longitude,
                        },
                      } ]),function(m,index){return _c('GmapMarker',{key:index,attrs:{"position":m.position,"clickable":true,"draggable":false}})}),1)],1):_vm._e()])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3","sm":"3","md":"3"}},[_c('v-textarea',{attrs:{"label":"Unit # / Housing Lot #","type":"unit","rows":"1","no-resize":"","requred":"","hint":"Type 'NA' if not applicable"},model:{value:(_vm.unit),callback:function ($$v) {_vm.unit=$$v},expression:"unit"}})],1),_c('v-col',{attrs:{"cols":"9","sm":"9","md":"9"}},[_c('vuetify-google-autocomplete',{ref:"map",attrs:{"id":"map","error-messages":_vm.errors.collect('address'),"required":"","data-vv-name":"address","country":"ph","disabled":false,"classname":"form-control","placeholder":"Please type your building name","placeName":"true"},on:{"placechanged":_vm.getAddressData,"change":_vm.updateAddress}}),_c('font',{attrs:{"size":"2","color":"red"}},[_vm._v(_vm._s(this.tip))])],1)],1)],1)],_c('Loading',{attrs:{"message":"Submitting Signup Information...","showDialog":_vm.showLoading}})],2),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"success ApplyNow",attrs:{"disabled":!_vm.validate(),"x-large":""},on:{"click":_vm.submit}},[_c('font',{attrs:{"color":"white"}},[_vm._v(" Create User ")])],1),_c('v-spacer')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }